import React from "react";

import {
	Container,
	Row,
	Col,
	Table,
	Dropdown,
	DropdownButton,
	ButtonGroup,
} from "react-bootstrap";

import RealtimeConnection from "../../utils/RealtimeConnection";
import "../../assets/css/Forms.css";
import Translate from "../../utils/Translate";

import _FieldInput from "./Partials/_InputField"

export default function PlanningSheet({
	canEdit,
	dispatch,
	planningSheet,
	session,
}) {
	function closePlanning(e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		dispatch({ type: "planningSheet", value: false });
	}
	function dropDownChange(
		e,
		alias,
		parentIndex,
		itemIndex,
		preIndex,
		currentIndex
	) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		RealtimeConnection.changePlanningSheet({
			type: "planningSheet",
			year: session.formSelectedYear,
			alias: alias,
			status: "dropDown",
			parentIndex: parentIndex,
			itemIndex: itemIndex,
			field: currentIndex,
			pre: preIndex,
			value: true,
		});
	}
	const inputChanged = (alias, parentIndex, value, fieldIndex, nestedIndex) => {
		RealtimeConnection.changePlanningSheet({
			type: "planningSheet",
			year: session.formSelectedYear,
			alias: alias,
			parentIndex: parentIndex,
			value: value,
			fieldIndex: fieldIndex,
			nestedIndex: nestedIndex,
		});
	}
	function changeFormYear(value) {
		RealtimeConnection.changeFormYear({
			year: value,
			type: "planningSheet",
		});
	}
	let yearsOptions = [];
	for (let i = 0; i <= session.year; i++) {
		yearsOptions.push(
			<Dropdown.Item
				key={i}
				eventKey={i}
				onClick={() => changeFormYear(i)}
			>
				<Translate alias="Board" word="Year" /> {i}
			</Dropdown.Item>
		);
	}

	let formStatus = canEdit ? true : false;
	if (session.formSelectedYear !== session.year) {
		formStatus = false;
	}

	return (
		<Container className="formContainer">
			<Row>
				<Col>
					<div className="formHeader">
						<div className="headerTitle bold">
							<Translate alias="Forms" word="Planning Sheet" />
						</div>
						<DropdownButton
							as={ButtonGroup}
							variant="outline-secondary"
							title={ <Translate alias="Board" word={ "Year" } append={ session.formSelectedYear } /> }
							size="sm"
							style={{ marginLeft: "10px" }}
						>
							{yearsOptions}
						</DropdownButton>
						<div className="closeForm" onClick={closePlanning}>
							X
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					{planningSheet.first && (
						<Table bordered>
							<tbody>
								<tr>
									<td colSpan={6}>
										<span className="header-text">
											{" "}<Translate alias="Forms" word="Credit up-take" />{" "}
										</span>
									</td>
								</tr>
								{planningSheet.first.fields.map((field, index) => (
										<tr key={ `First_${index}` }>
											<td>
												<div className="bold"><Translate alias="Forms" word={ field.title } /></div>
												<span className="smallText"><Translate alias="Forms" word={ field.subText } /></span>
											</td>
											{
												field.fields.map((item, itemIndex) => (
													<td key={ `First_TD_${itemIndex}` }>
														<div className="grid-div first-group ">
															<div className="smallText ">
																<Translate alias="Forms" word={ item.name } />
															</div>
															<div className="line-border" />{" "}
															<div>
																<input
																	type="checkbox"
																	checked={ item.value }
																	onChange={(e) =>
																		inputChanged("first", index, !item.value, itemIndex)
																	}
																/>
															</div>
														</div>
													</td>
												))
											}
											<td>
												<_FieldInput
													year={ session.formSelectedYear }
													value={ field.sum }
													alias={ "first" }
													index={ index }
												/>
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan={5}></td>
									<td>
										<input
											type="number"
											className="input-number-planning"
											value={planningSheet.first.fields
												.map((a) => a.sum)
												.reduce((a, b) => a + b)}
											disabled={true}
										/>
									</td>
								</tr>
								{planningSheet.second.fields.map((field, index) => (
										<tr key={ `Second_${index}` }>
											<td>
												<div className="bold"><Translate alias="Forms" word={ field.title } /></div>
												<span className="smallText"><Translate alias="Forms" word={ field.subText } /></span>
											</td>
											{field.fields.map((item, itemIndex) => (
													<td key={ `Second_TD_${itemIndex}` }>
														<div className="grid-div second-group">
															<div className="smallText">
																<Translate alias="Forms" word={ item.name } />
															</div>
															<div>
																<DropdownButton
																	as={
																		ButtonGroup
																	}
																	variant="outline-secondary"
																	className="dropdown-planning"
																	title={ <Translate alias="Forms" word={ item.dropDown.find((x) => x.value).key } /> }
																	size="sm"
																	// disabled={!formStatus}
																>
																	{item.dropDown.map((option, currentIndex) => (
																			<Dropdown.Item
																				key={ currentIndex }
																				onClick={(e) =>
																					dropDownChange(
																						e,
																						"second",
																						index,
																						itemIndex,
																						item.dropDown.findIndex((x) => x.value),
																						currentIndex
																					)
																				}
																				eventKey={option.key}
																			>
																				<Translate alias="Forms" word={ option.key } />
																			</Dropdown.Item>
																		)
																	)}
																</DropdownButton>
															</div>
														</div>
													</td>
												)
											)}
											<td>
												<_FieldInput
													year={ session.formSelectedYear }
													value={ field.sum }
													alias={ "second" }
													index={ index }
												/>
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan={5}></td>
									<td>
										<div>
											<input
												type="number"
												className="input-number-planning"
												value={planningSheet.second.fields
													.map((a) => a.sum)
													.reduce((a, b) => a + b)}
												disabled={true}
											/>
										</div>
									</td>
								</tr>

								{planningSheet.third.fields.map((field, index) => (
										<tr key={ `Third_${index}` }>
											<td>
												<div className="bold"><Translate alias="Forms" word={ field.title } /></div>
												<span className="smallText"><Translate alias="Forms" word={ field.subText } /></span>
											</td>
											{field.fields.map((item, itemIndex) => {
												let itemText = item.name
												if (item.name.includes("silver ")) {
													const itemArray = item.name.split(" ")
													itemText = itemArray[0].charAt(0).toUpperCase() + itemArray[0].slice(1);
												}else if (item.name.includes("gold ")) {
													const itemArray = item.name.split(" ")
													itemText = itemArray[0].charAt(0).toUpperCase() + itemArray[0].slice(1);
												}else if (item.name.includes("green ")) {
													const itemArray = item.name.split(" ")
													itemText = itemArray[0].charAt(0).toUpperCase() + itemArray[0].slice(1);
												}else if (item.name.includes("white ")) {
													const itemArray = item.name.split(" ")
													itemText = itemArray[0].charAt(0).toUpperCase() + itemArray[0].slice(1);
												}

												return (
													<td key={ `Third_TD_${itemIndex}` }>
														<div className="grid-div third-group">
															<div
																className="smallText border-bottom"
																style={{ whiteSpace: "break-spaces" }}
															>
																<Translate alias="Forms" word={ itemText } />
															</div>
															{item.fields ? (
																item.fields.map((itemField, itemFieldIndex) => {
																	const keyArray = itemField.key.split(" ")
																	const keyText = keyArray[0].charAt(0).toUpperCase() + keyArray[0].slice(1);
																	
																	return (
																		<div key={ itemFieldIndex }>
																			<div className="smallText border-bottom">
																				{
																					(keyText &&
																						<Translate alias="Forms" word={ keyText } />		
																					)
																				}
																				{ ` ${keyArray[1] ? keyArray[1] : ""}`}
																			</div>
																			<div>
																				<_FieldInput
																					year={ session.formSelectedYear }
																					value={ itemField.value }
																					alias={ "third" }
																					index={ index }
																					itemIndex={ itemIndex }
																					itemFieldIndex={ itemFieldIndex }
																				/>
																				{ /*<input
																					type="number"
																					className="input-number-planning"
																					value={ itemField.value }
																					onChange={(value) =>
																						inputChanged(
																							"third",
																							index,
																							value,
																							itemIndex,
																							itemFieldIndex
																						)
																					}
																					// disabled={!this.props.canEdit}
																					decimalSeparator={","}
																				/>*/ }
																			</div>
																		</div>
																	)
																}
																)
															) : (
																<div>
																	<_FieldInput
																		year={ session.formSelectedYear }
																		value={ item.value }
																		alias={ "third" }
																		index={ index }
																		itemIndex={ itemIndex }
																	/>

																	{ /*<input
																		type="number"
																		className="input-number-planning"
																		value={ item.value }
																		onChange={(value) =>
																			inputChanged("third", index, value, itemIndex)
																		}
																		// disabled={!this.props.canEdit}
																		decimalSeparator={","}
																	/>*/ }
																</div>
															)}
														</div>
													</td>
												)
											}
											)}
											{field.fields.length === 3 && (
												<td></td>
											)}
											<td>
												<_FieldInput
													year={ session.formSelectedYear }
													value={ field.sum }
													alias={ "third" }
													index={ index }
												/>
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan={5}></td>
									<td>
										<input
											type="number"
											className="input-number-planning"
											value={planningSheet.third.fields
												.map((a) => a.sum)
												.reduce((a, b) => a + b)}
											disabled={true}
										/>
									</td>
								</tr>
								<tr className="header-text">
									{" "}<Translate alias="Forms" word="Invest in training:" />{" "}
								</tr>
								{planningSheet.forth.fields.map((field, index) => (
										<tr key={ `Fourth_${index}` }>
											<td>
												<div className="bold"><Translate alias="Forms" word={ field.title } /></div>
												<span className="smallText"><Translate alias="Forms" word={ field.subText } /></span>
											</td>
											{field.fields.map((item, itemIndex) => (
													<td key={ `Fourth_TD_${itemIndex}` }>
														<div className="grid-div forth-group">
															<div
																className="smallText border-bottom"
																style={{
																	whiteSpace: "break-spaces",
																}}
															>
																<Translate alias="Forms" word={ item.name } />
															</div>
															
															{item.dropDown ? (
																<td>
																	<div>
																		<DropdownButton
																			as={
																				ButtonGroup
																			}
																			variant="outline-secondary"
																			className="dropdown-planning"
																			title={ <Translate alias="Forms" word={ item.dropDown.find((x) => x.value).key } /> }
																			size="sm"
																			// disabled={!formStatus}
																		>
																			{item.dropDown.map((option, currentIndex) => (
																					<Dropdown.Item
																						key={ currentIndex }
																						onClick={(e) =>
																							dropDownChange(
																								e,
																								"forth",
																								index,
																								itemIndex,
																								item.dropDown.findIndex(
																									(x) => x.value
																								),
																								currentIndex
																							)
																						}
																						eventKey={option.key}
																					>
																						<Translate alias="Forms" word={ option.key } />
																					</Dropdown.Item>
																				)
																			)}
																		</DropdownButton>
																	</div>
																</td>
															) : (
																<div>
																	<td>
																		<_FieldInput
																			year={ session.formSelectedYear }
																			value={ item.value }
																			alias={ "forth" }
																			index={ index }
																			itemIndex={ itemIndex }
																		/>
																	</td>
																	{ /*<input
																		type="number"
																		className="input-number-planning"
																		value={ item.value }
																		onChange={(value) =>
																			inputChanged(
																				"forth",
																				index,
																				value,
																				itemIndex
																			)
																		}
																		// disabled={!this.props.canEdit}
																		decimalSeparator={","}
																	/>*/ }
																</div>
															)}
														</div>
													</td>
												)
											)}
											{field.fields.length === 2 && (
												<td></td>
											)}
											{field.fields.length === 2 && (
												<td></td>
											)}
											<td>
												<_FieldInput
													year={ session.formSelectedYear }
													value={ field.sum }
													alias={ "forth" }
													index={ index }
												/>
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan={5}></td>
									<td>
										<input
											type="number"
											className="input-number-planning"
											value={planningSheet.forth.fields
												.map((a) => a.sum)
												.reduce((a, b) => a + b)}
											disabled={true}
										/>
									</td>
								</tr>
								{planningSheet.fifth.fields.map(
									(field, index) => (
										<tr key={ `Fifth_${index}` }>
											<td>
												<div className="bold"><Translate alias="Forms" word={ field.title } /></div>
												<span className="smallText"><Translate alias="Forms" word={ field.subText } /></span>
											</td>
											{field.fields.map((item, itemIndex) => (
													<td key={ `Fifth_TD_${itemIndex}` }>
														<div className="grid-div fifth-group">
															<div
																className="smallText border-bottom"
																style={{
																	whiteSpace: "break-spaces",
																}}
															>
																<Translate alias="Forms" word={ item.name } />
															</div>

															<div>
																<_FieldInput
																	year={ session.formSelectedYear }
																	value={ item.value }
																	alias={ "fifth" }
																	index={ index }
																	itemIndex={ itemIndex }
																/>
																{ /*<input
																	type="number"
																	className="input-number-planning"
																	value={ item.value }
																	onChange={(value) =>
																		inputChanged(
																			"fifth",
																			index,
																			value,
																			itemIndex
																		)
																	}
																	// disabled={!this.props.canEdit}
																	decimalSeparator={","}
																/>*/ }
															</div>
														</div>
														)
													</td>
												)
											)}

											<td>
												<_FieldInput
													year={ session.formSelectedYear }
													value={ field.sum }
													alias={ "fifth" }
													index={ index }
												/>
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan={5}></td>
									<td>
										<div>
											<input
												type="number"
												className="input-number-planning"
												value={
													planningSheet.first.fields
														.map((a) => a.sum)
														.reduce(
															(a, b) => a + b
														) +
													planningSheet.second.fields
														.map((a) => a.sum)
														.reduce(
															(a, b) => a + b
														) +
													planningSheet.third.fields
														.map((a) => a.sum)
														.reduce(
															(a, b) => a + b
														) +
													planningSheet.forth.fields
														.map((a) => a.sum)
														.reduce(
															(a, b) => a + b
														) +
													planningSheet.fifth.fields
														.map((a) => a.sum)
														.reduce((a, b) => a + b)
												}
												disabled={true}
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Col>
			</Row>
		</Container>
	);
}
