const planningSheet = {
	first: {
		fields: [
			{
				title: "Forecast",
				subText: "($3)",
				fields: [
					{
						name: "R1",
						value: false,
					},
					{
						name: "R2",
						value: false,
					},
					{
						name: "R3",
						value: false,
					},
					{
						name: "R4",
						value: false,
					},
				],
				sum: 0,
			},
			{
				title: "Expansion",
				subText: "($2 per pin)",
				fields: [
					{
						name: "R1",
						value: false,
					},
					{
						name: "R2",
						value: false,
					},
					{
						name: "R3",
						value: false,
					},
					{
						name: "R4",
						value: false,
					},
				],
				sum: 0,
			},
		],
		totalSum: 0,
	},

	second: {
		fields: [
			{
				title: "Open/Upgrade location",
				subText: "Effect of exclusive location: Reputation +1",
				fields: [
					{
						name: "R1",
						dropDown: [
							{ key: "no", value: true },
							{ key: "basic - rent ($3)", value: false },
							{ key: "basic - buy ($5)", value: false },
							{ key: "exclusive - rent ($5)", value: false },
							{ key: "exclusive - buy ($10)", value: false },
							{
								key: "upgrade to exclusive ($depends)",
								value: false,
							},
						],
					},
					{
						name: "R2",
						dropDown: [
							{ key: "no", value: true },
							{ key: "basic - rent ($3)", value: false },
							{ key: "basic - buy ($5)", value: false },
							{ key: "exclusive - rent ($5)", value: false },
							{ key: "exclusive - buy ($10)", value: false },
							{
								key: "upgrade to exclusive ($depends)",
								value: false,
							},
						],
					},
					{
						name: "R3",
						dropDown: [
							{ key: "no", value: true },
							{ key: "basic - rent ($3)", value: false },
							{ key: "basic - buy ($5)", value: false },
							{ key: "exclusive - rent ($5)", value: false },
							{ key: "exclusive - buy ($10)", value: false },
							{
								key: "upgrade to exclusive ($depends)",
								value: false,
							},
						],
					},
					{
						name: "R4",
						dropDown: [
							{ key: "no", value: true },
							{ key: "basic - rent ($3)", value: false },
							{ key: "basic - buy ($5)", value: false },
							{ key: "exclusive - rent ($5)", value: false },
							{ key: "exclusive - buy ($10)", value: false },
							{
								key: "upgrade to exclusive ($depends)",
								value: false,
							},
						],
					},
				],
				sum: 0,
			},
			{
				title: "Close/Downgrade location",
				subText: "Effect: Reputation -1",
				fields: [
					{
						name: "R1",
						dropDown: [
							{ key: "close", value: true },
							{ key: "downgrade", value: false },
						],
					},
					{
						name: "R2",
						dropDown: [
							{ key: "close", value: true },
							{ key: "downgrade", value: false },
						],
					},
					{
						name: "R3",
						dropDown: [
							{ key: "close", value: true },
							{ key: "downgrade", value: false },
						],
					},
					{
						name: "R4",
						dropDown: [
							{ key: "close", value: true },
							{ key: "downgrade", value: false },
						],
					},
				],
				sum: 0,
			},
		],
		totalSum: 0,
	},
	third: {
		fields: [
			{
				title: "Lay-off employees",
				subText: "Effect: Reputation -1",
				fields: [
					{
						name: "gold ($4)",
						value: 0,
					},
					{
						name: "silver ($2)",
						value: 0,
					},
					{
						name: "green ($0.5)",
						value: 0,
					},
				],
				sum: 0,
			},
			{
				title: "Hire employees",
				subText: "Hiring is free (except pot. Sign-on fee for gold).",
				fields: [
					{
						name: "R1",
						fields: [
							{ key: "Gold", value: 0 },
							{ key: "Silver", value: 0 },
							{ key: "Green", value: 0 },
						],
					},
					{
						name: "R2",
						fields: [
							{ key: "Gold", value: 0 },
							{ key: "Silver", value: 0 },
							{ key: "Green", value: 0 },
						],
					},
					{
						name: "R3",
						fields: [
							{ key: "Gold", value: 0 },
							{ key: "Silver", value: 0 },
							{ key: "Green", value: 0 },
						],
					},
					{
						name: "R4",
						fields: [
							{ key: "Gold", value: 0 },
							{ key: "Silver", value: 0 },
							{ key: "Green", value: 0 },
						],
					},
				],
				sum: 0,
			},
			{
				title: "Lay-off employees",
				subText: "Effect: Reputation -1",
				fields: [
					{
						name: "gold ($4)",
						value: 0,
					},
					{
						name: "silver ($2)",
						value: 0,
					},
					{
						name: "green ($0.5)",
						value: 0,
					},
				],
				sum: 0,
			},
			{
				title: "Relocate people at additional salary costs",
				subText: "",
				fields: [
					{
						name: "gold ($5)",
						value: 0,
					},
					{
						name: "silver ($2)",
						value: 0,
					},
					{
						name: "green ($1)",
						value: 0,
					},
				],
				sum: 0,
			},
			{
				title: "Sign people from competitor",
				subText: "Only possible if reputation is high enough",
				fields: [
					{
						name: "gold ($4)",
						value: 0,
					},
					{
						name: "silver ($2)",
						value: 0,
					},
					{
						name: "green ($0.5)",
						value: 0,
					},
				],
				sum: 0,
			},
			{
				title: "Overall salary",
				subText:
					"incl. pot. newly hired people, excl. relocation costs",
				fields: [
					{
						name: "gold ($5)",
						value: 0,
					},
					{
						name: "silver ($2)",
						value: 0,
					},
					{
						name: "green ($1)",
						value: 0,
					},
					{
						name: "white ($0.5)",
						value: 0,
					},
				],
				sum: 0,
			},
		],
		totalSum: 0,
	},
	forth: {
		fields: [
			{
				title: "1) Train ALL greens",
				subText: "Effect: Skills green +1 per training ",
				fields: [
					{
						name: "How many in total? ($0.25)",
						value: 0,
					},
					{
						name: "How often? (max. 4 per year)",
						value: 0,
					},
				],
				sum: 0,
			},
			{
				title: "2) Develop green to silver",
				subText: "Effect: Motivation silver +1 in respective region(s)",
				fields: [
					{
						name: "How many? ($2)",
						value: 0,
					},
					{
						name: "Which region?",
						value: 0,
					},
				],
				sum: 0,
			},
			{
				title: "3) Develop silver to gold",
				subText: "Effect: No effect",
				fields: [
					{
						name: "How many? ($5)",
						value: 0,
					},
					{
						name: "Which region?",
						value: 0,
					},
				],
				sum: 0,
			},
			{
				title: "Buy or upgrade equipment",
				subText: "Effect of superior equipment: Motivation green +1",
				fields: [
					{
						name: "R1",
						dropDown: [
							{ key: "basic ($ 5)", value: true },
							{ key: "superior ($ 10)", value: false },
							{
								key: "upgrade to superior ($ depends)",
								value: false,
							},
						],
					},
					{
						name: "R2",
						dropDown: [
							{ key: "basic ($ 5)", value: true },
							{ key: "superior ($ 10)", value: false },
							{
								key: "upgrade to superior ($ depends)",
								value: false,
							},
						],
					},
					{
						name: "R3",
						dropDown: [
							{ key: "basic ($ 5)", value: true },
							{ key: "superior ($ 10)", value: false },
							{
								key: "upgrade to superior ($ depends)",
								value: false,
							},
						],
					},
					{
						name: "R4",
						dropDown: [
							{ key: "basic ($ 5)", value: true },
							{ key: "superior ($ 10)", value: false },
							{
								key: "upgrade to superior ($ depends)",
								value: false,
							},
						],
					},
				],
				sum: 0,
			},
		],
		totalSum: 0,
	},
	fifth: {
		fields: [
			{
				title: "Marketing",
				subText: "(max. 5 per region)",
				fields: [
					{
						name: "R1",
						value: 0,
					},
					{
						name: "R2",
						value: 0,
					},
					{
						name: "R3",
						value: 0,
					},
					{
						name: "R4",
						value: 0,
					},
				],
				sum: 0,
			},
		],
		totalSum: 0,
	},
};

export default planningSheet;
